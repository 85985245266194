var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspection-records-map-box" },
    [
      _c("div", { attrs: { id: "cesiumContainer_inspection_records" } }),
      _vm.siderContentShow
        ? _c("div", { ref: "footer", staticClass: "footer" }, [
            _c("div", { staticClass: "play-controller" }, [
              _c("div", { style: "width:" + _vm.processingTextWidth + "px" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.passedTime + "/" + _vm.totalTime) +
                    "\n      "
                ),
              ]),
              _c(
                "span",
                { staticClass: "video-play", on: { click: _vm.triggerPlay } },
                [
                  _vm.videoLoading
                    ? _c("i", { staticClass: "el-icon-loading" })
                    : _vm.play
                    ? _c("i", { staticClass: "el-icon-video-pause" })
                    : _c("i", { staticClass: "el-icon-video-play" }),
                ]
              ),
              _c(
                "div",
                {
                  ref: "processing",
                  staticClass: "processing",
                  on: { click: _vm.setControllerPosition },
                },
                [
                  _c("el-progress", {
                    attrs: { percentage: _vm.percentage, "show-text": false },
                  }),
                  _c("div", {
                    staticClass: "processing-controller",
                    style: "left:" + _vm.percentage + "%",
                    on: { mousedown: _vm.startProgress },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "tool-controller" }),
          ])
        : _vm._e(),
      _vm.selectInspection && _vm.selectInspection.id
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingInspection,
                  expression: "loadingInspection",
                },
              ],
              staticClass: "inspection-pannel",
              class: _vm.InspectionInfoClass,
              attrs: {
                "element-loading-text": _vm.$t("loading"),
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            },
            [
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("inspection.name")) + ": "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "pannel-item-content",
                    on: {
                      click: function ($event) {
                        return _vm.openRightPannel(
                          _vm.showInspection.inspectionId
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.showInspection.inspectionName) +
                        "\n      "
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("objectType")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.getInspectionTypeName(
                          _vm.showInspection.inspectionType
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("projectBelong")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.projectName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("taskBelong")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.taskName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("inspection.device")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.equipmentName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("startTime")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.startTime) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("endTime")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.endTime) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("inspection.duration")) +
                      ":\n      "
                  ),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.inspectionDuration) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("inspection.hasWarning")) +
                      ":\n      "
                  ),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.showInspection.alarmDataVoList &&
                          _vm.showInspection.alarmDataVoList.length > 0
                          ? _vm.$t("has")
                          : _vm.$t("hasNo")
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("notes")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showInspection.remark) +
                      "\n        "
                  ),
                  _c("i", {
                    staticClass: "el-icon-edit-outline edit-icon",
                    on: { click: _vm.editRecord },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showWarningDetail
        ? _c("WarningDetail", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingWarningDetail,
                expression: "loadingWarningDetail",
              },
            ],
            class: _vm.WarningDetailClass,
            attrs: {
              "element-loading-text": _vm.$t("loading"),
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(0, 0, 0, 0.8)",
              top: 28,
              right: _vm.WarningDetailRight,
              selectWarning: _vm.selectWarning,
            },
            on: { close: _vm.closeWarningDetail },
          })
        : _vm._e(),
      _c(
        "div",
        {
          class: _vm.showRightPanel ? "open-oilInspection-panel" : "",
          attrs: { id: "baseController" },
        },
        [
          _c("img", {
            style: "transform: rotate(" + _vm.degrees + "deg)",
            attrs: {
              id: "navigationHelpButtonContainer",
              src: "/img/map/compass.png",
            },
          }),
          _c("div", { staticClass: "other" }, [
            _c(
              "div",
              {
                staticClass: "btn zoomBtn bottomBorder",
                staticStyle: {
                  "border-top-right-radius": "4px",
                  "border-top-left-radius": "4px",
                },
                on: {
                  click: function ($event) {
                    return _vm.zoom(1)
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/map/放大.png" } })]
            ),
            _c(
              "div",
              {
                staticClass: "btn zoomBtn",
                staticStyle: {
                  "border-bottom-right-radius": "4px",
                  "border-bottom-left-radius": "4px",
                },
                on: {
                  click: function ($event) {
                    return _vm.zoom(-1)
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/map/缩小.png" } })]
            ),
            _c(
              "div",
              {
                attrs: { title: "" + (_vm.isVec ? "卫星图" : "矢量图") },
                on: { click: _vm.switchLayerType },
              },
              [
                _c("img", {
                  staticClass: "layerSwitch",
                  attrs: {
                    src: "/img/map/" + (_vm.isVec ? "3D.png" : "2D.png"),
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "common-map-dialog",
          attrs: {
            width: "776px",
            title: _vm.$t("inspection.editRecord"),
            visible: _vm.recordShow,
            "before-close": _vm.closeRecord,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.recordShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticStyle: { "margin-bottom": "8px" } }, [
                _vm._v(_vm._s(_vm.$t("notes"))),
              ]),
              _c("el-input", {
                staticClass: "uav-textarea",
                attrs: { type: "textarea", placeholder: _vm.$t("inputPlease") },
                model: {
                  value: _vm.remark,
                  callback: function ($$v) {
                    _vm.remark = $$v
                  },
                  expression: "remark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeRecord } }, [
                _vm._v(_vm._s(_vm.$t("cancelText"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.recordLoading, type: "primary" },
                  on: { click: _vm.submitRecord },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "sider-content",
          class: _vm.showRightPanel ? "open-oilInspection-panel" : "",
          style:
            "width: 460px; right: 28px; top: " +
            (_vm.windowHeight === "normal" ? "420" : "260") +
            "px;",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.siderContentShow,
                  expression: "siderContentShow",
                },
              ],
              staticClass: "content-header",
            },
            [
              _c("span", { staticClass: "content-title" }, [
                _vm._v("视频回放"),
              ]),
              _c(
                "span",
                { staticClass: "close", on: { click: _vm.closeSiderContent } },
                [_c("img", { attrs: { src: "/img/map/close.png" } })]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "content",
              class: _vm.siderContentShow ? "height-258" : "",
            },
            [
              _c(
                "div",
                { staticClass: "video-container" },
                [
                  _c("xg-player", {
                    ref: "videoLive",
                    attrs: {
                      dialogData: this.showInspection,
                      videoUrl: _vm.videoUrl,
                      videoType: "mp4",
                      controls: false,
                      lonArr: _vm.lonArr,
                      show: false,
                    },
                    on: {
                      "warning-success": _vm.warningSuccess,
                      showLive: _vm.showLive,
                      play: _vm.videoPlay,
                      pause: _vm.videoPause,
                      ended: _vm.videoEnd,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("oilInspection-panel", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingRightPanel,
            expression: "loadingRightPanel",
          },
        ],
        staticClass: "oilInspection-panel",
        attrs: {
          canEdit: false,
          pathList: _vm.inspectionPathList,
          entity: _vm.currentEntity,
          "element-loading-text": _vm.$t("loading"),
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(0, 0, 0, 0.8)",
          visible: _vm.showRightPanel,
        },
        on: {
          close: _vm.closePanel,
          "update:visible": function ($event) {
            _vm.showRightPanel = $event
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "scaler",
          class: _vm.showRightPanel ? "open-oilInspection-panel" : "",
        },
        [
          _c("div", {
            staticClass: "scaler-text",
            domProps: {
              textContent: _vm._s(
                _vm.scale < 1000 ? _vm.scale + "m" : _vm.scale / 1000 + "km"
              ),
            },
          }),
          _c("div", { staticClass: "scaler-ui" }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }