var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inspection-records-bar" }, [
    _c("div", { staticClass: "inspection-records-title" }, [
      _vm._v("\n    " + _vm._s(_vm.$t("inspection.record")) + "\n  "),
    ]),
    _c(
      "div",
      { staticClass: "inspection-records-search" },
      [
        _c(
          "el-select",
          {
            staticClass: "uav-select",
            staticStyle: { width: "145px" },
            attrs: {
              clearable: "",
              placeholder: _vm.$t("allType"),
              size: "small",
            },
            on: { change: _vm.searchHandle },
            model: {
              value: _vm.form.inspectionType,
              callback: function ($$v) {
                _vm.$set(_vm.form, "inspectionType", $$v)
              },
              expression: "form.inspectionType",
            },
          },
          _vm._l(_vm.inspectionTypeList, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
        _c(
          "el-input",
          {
            staticClass: "uav-input",
            staticStyle: { width: "150px", "margin-left": "10px" },
            attrs: { size: "small", placeholder: _vm.$t("inspection.name") },
            on: { change: _vm.searchHandle },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          },
          [
            _c("template", { slot: "suffix" }, [
              _c("div", { staticClass: "search-icon-container" }, [
                _c("i", {
                  staticClass: "el-icon-search",
                  on: { click: _vm.searchHandle },
                }),
              ]),
            ]),
          ],
          2
        ),
        _c("div", { staticClass: "margin-separate" }),
        _c(
          "el-select",
          {
            staticClass: "uav-select",
            staticStyle: { width: "145px" },
            attrs: {
              clearable: "",
              placeholder: _vm.$t("allProjects"),
              size: "small",
            },
            on: { change: _vm.searchHandle },
            model: {
              value: _vm.form.projectId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "projectId", $$v)
              },
              expression: "form.projectId",
            },
          },
          _vm._l(_vm.projectList, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
        _c(
          "el-select",
          {
            staticClass: "uav-select",
            staticStyle: { width: "150px", "margin-left": "10px" },
            attrs: {
              clearable: "",
              disabled: !_vm.projectId,
              placeholder: _vm.$t("allTasks"),
              size: "small",
            },
            on: { change: _vm.searchHandle },
            model: {
              value: _vm.form.taskId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "taskId", $$v)
              },
              expression: "form.taskId",
            },
          },
          _vm._l(_vm.taskList, function (item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.label, value: item.value },
            })
          }),
          1
        ),
        _c("div", { staticClass: "margin-separate" }),
        _c("el-date-picker", {
          staticClass: "uav-date-range",
          staticStyle: { width: "305px" },
          attrs: {
            size: "small",
            type: "datetimerange",
            "range-separator": "至",
            "start-placeholder": _vm.$t("startTime"),
            "end-placeholder": _vm.$t("endTime"),
            "default-time": ["00:00:00", "23:59:59"],
            "value-format": "yyyy-MM-dd HH:mm:ss",
          },
          on: { change: _vm.searchHandle },
          model: {
            value: _vm.form.time,
            callback: function ($$v) {
              _vm.$set(_vm.form, "time", $$v)
            },
            expression: "form.time",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "record-item-box",
        attrs: {
          "element-loading-text": _vm.$t("loading"),
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(0, 0, 0, 0.8)",
        },
      },
      _vm._l(_vm.inspectionList, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "record-item",
            class:
              _vm.selectInspection.id === item.id ? "record-item-active" : "",
            on: {
              mouseleave: _vm.leaveRecord,
              click: function ($event) {
                return _vm.chooseRecord(item)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "record-title" },
              [
                _c(
                  "el-tooltip",
                  { attrs: { content: item.name, placement: "top" } },
                  [
                    _c("div", { staticClass: "record-describe-mane" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]
                ),
                _c("span", { staticClass: "control-icon" }, [
                  item.id === _vm.selectInspection.id
                    ? _c("i", {
                        staticClass: "el-icon-aim",
                        staticStyle: { "margin-right": "4px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.flyTo(item)
                          },
                        },
                      })
                    : _vm._e(),
                  item.id === _vm.selectInspection.id
                    ? _c("i", {
                        staticClass: "el-icon-delete",
                        class: _vm.deleting ? "bgc-red" : "",
                        staticStyle: { "margin-right": "4px" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.deleteInspection(item)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "record-task" }, [
              _vm._v(_vm._s(_vm.getInspectionTypeName(item.inspectionType))),
            ]),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: item.projectName + " -- " + item.taskName,
                  placement: "right",
                },
              },
              [
                _c("div", { staticClass: "record-describe" }, [
                  _vm._v(
                    _vm._s(item.projectName) + " -- " + _vm._s(item.taskName)
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "record-time" }, [
              _vm._v(_vm._s(item.startTime) + " -- " + _vm._s(item.endTime)),
            ]),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "left-table-pagination-line" },
      [
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.page,
            "page-size": _vm.size,
            "pager-count": 3,
            size: "small",
            background: "",
            layout: "total, prev, pager, next",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
            "update:currentPage": function ($event) {
              _vm.page = $event
            },
            "update:current-page": function ($event) {
              _vm.page = $event
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }