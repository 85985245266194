<template>
  <div class="container normal mission-center-box">
    <InspectionRecordsBar/>
    <Records/>
  </div>
</template>
  
<script>
  import Records from "./inspection-records/index"
  import InspectionRecordsBar from "./InspectionRecordsBar/";
  
  export default {
    name: "inspection-records",
    components: {
      Records,
      InspectionRecordsBar
    },
    data() {
      return {};
    },
    methods: {
    },
  };
</script>
  
<style lang="scss">
  .mission-center-box{
    height: 100%;
    display: flex;
    .mission-center-lastItem{
      flex-grow: 1;
    }
  }
</style>
  