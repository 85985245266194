<template>
  <div class="inspection-records-map-box">
    <div id="cesiumContainer_inspection_records"></div>
    <!-- 进度条 -->
    <div v-if="siderContentShow" ref="footer" class="footer">
      <div class="play-controller">
        <div :style="`width:${processingTextWidth}px`">
          {{ `${passedTime}/${totalTime}` }}
        </div>
        <span @click="triggerPlay" class="video-play">
          <i v-if="videoLoading" class="el-icon-loading"></i>
          <i v-else-if="play" class="el-icon-video-pause"></i>
          <i v-else class="el-icon-video-play"></i>
        </span>
        <div @click="setControllerPosition" ref="processing" class="processing">
          <el-progress
            :percentage="percentage"
            :show-text="false"
          ></el-progress>
          <div
            @mousedown="startProgress"
            :style="`left:${percentage}%`"
            class="processing-controller"
          ></div>
        </div>
      </div>
      <div class="tool-controller"></div>
    </div>
    <!-- 巡检对象信息面板 -->
    <div
      v-if="selectInspection && selectInspection.id"
      v-loading="loadingInspection"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :class="InspectionInfoClass"
      class="inspection-pannel"
    >
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("inspection.name") }}: </span>
        <span
          @click="openRightPannel(showInspection.inspectionId)"
          class="pannel-item-content"
        >
          {{ showInspection.inspectionName }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("objectType") }}: </span>
        <span class="pannel-item-content">
          {{ getInspectionTypeName(showInspection.inspectionType) }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("projectBelong") }}: </span>
        <span class="pannel-item-content">
          {{ showInspection.projectName }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("taskBelong") }}: </span>
        <span class="pannel-item-content">
          {{ showInspection.taskName }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("inspection.device") }}: </span>
        <span class="pannel-item-content">
          {{ showInspection.equipmentName }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("startTime") }}: </span>
        <span class="pannel-item-content">
          {{ showInspection.startTime }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("endTime") }}: </span>
        <span class="pannel-item-content">
          {{ showInspection.endTime }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title">
          {{ $t("inspection.duration") }}:
        </span>
        <span class="pannel-item-content">
          {{ showInspection.inspectionDuration }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title">
          {{ $t("inspection.hasWarning") }}:
        </span>
        <span class="pannel-item-content">
          {{
            showInspection.alarmDataVoList &&
            showInspection.alarmDataVoList.length > 0
              ? $t("has")
              : $t("hasNo")
          }}
        </span>
      </div>
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("notes") }}: </span>
        <span class="pannel-item-content">
          {{ showInspection.remark }}
          <i @click="editRecord" class="el-icon-edit-outline edit-icon"></i>
        </span>
      </div>
    </div>
    <!-- 报警详情面板 -->
    <WarningDetail
      v-loading="loadingWarningDetail"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :class="WarningDetailClass"
      :top="28"
      :right="WarningDetailRight"
      :selectWarning="selectWarning"
      v-if="showWarningDetail"
      @close="closeWarningDetail"
    >
    </WarningDetail>
    <!-- 右下工具栏 -->
    <div
      id="baseController"
      :class="showRightPanel ? 'open-oilInspection-panel' : ''"
    >
      <img
        id="navigationHelpButtonContainer"
        src="/img/map/compass.png"
        :style="'transform: rotate(' + degrees + 'deg)'"
      />
      <div class="other">
        <!-- <div
                    class="btn zoomBtn model-changer flexCenter"
                    @click="changeViewModel">
                    {{ viewModel === 3 ? "2D" : "3D" }}
                </div> -->

        <div
          class="btn zoomBtn bottomBorder"
          style="border-top-right-radius: 4px; border-top-left-radius: 4px"
          @click="zoom(1)"
        >
          <img src="/img/map/放大.png" />
        </div>

        <div
          class="btn zoomBtn"
          style="
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          "
          @click="zoom(-1)"
        >
          <img src="/img/map/缩小.png" />
        </div>

        <div @click="switchLayerType" :title="`${isVec ? '卫星图' : '矢量图'}`">
          <img
            class="layerSwitch"
            :src="`/img/map/${isVec ? '3D.png' : '2D.png'}`"
          />
        </div>
      </div>
    </div>
    <!-- 备注 -->
    <el-dialog
      width="776px"
      :title="$t('inspection.editRecord')"
      :visible.sync="recordShow"
      :before-close="closeRecord"
      class="common-map-dialog"
      :close-on-click-modal="false"
      append-to-body
    >
      <div>
        <div style="margin-bottom: 8px">{{ $t("notes") }}</div>
        <el-input
          class="uav-textarea"
          type="textarea"
          :placeholder="$t('inputPlease')"
          v-model="remark"
        ></el-input>
      </div>
      <div slot="footer">
        <el-button @click="closeRecord">{{ $t("cancelText") }}</el-button>
        <el-button
          :loading="recordLoading"
          @click="submitRecord"
          type="primary"
          >{{ $t("submitText") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 视频回放 -->
    <div
      :class="showRightPanel ? 'open-oilInspection-panel' : ''"
      class="sider-content"
      :style="`width: 460px; right: 28px; top: ${
        windowHeight === 'normal' ? '420' : '260'
      }px;`"
    >
      <div v-show="siderContentShow" class="content-header">
        <span class="content-title">视频回放</span>
        <span class="close" @click="closeSiderContent">
          <img src="/img/map/close.png" />
        </span>
      </div>
      <div :class="siderContentShow ? 'height-258' : ''" class="content">
        <div class="video-container">
          <xg-player
            ref="videoLive"
            :dialogData="this.showInspection"
            :videoUrl="videoUrl"
            :videoType="'mp4'"
            :controls="false"
            @warning-success="warningSuccess"
            @showLive="showLive"
            @play="videoPlay"
            @pause="videoPause"
            @ended="videoEnd"
            :lonArr="lonArr"
            :show="false"
          />
        </div>
      </div>
    </div>
    <!-- 巡检对象属性面板 -->
    <oilInspection-panel
      class="oilInspection-panel"
      :canEdit="false"
      :pathList="inspectionPathList"
      :entity="currentEntity"
      @close="closePanel"
      v-loading="loadingRightPanel"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :visible.sync="showRightPanel"
    >
    </oilInspection-panel>
    <!-- 比例尺 -->
    <div
      class="scaler"
      :class="showRightPanel ? 'open-oilInspection-panel' : ''"
    >
      <div
        class="scaler-text"
        v-text="scale < 1000 ? `${scale}m` : `${scale / 1000}km`"
      ></div>
      <div class="scaler-ui"></div>
    </div>
  </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import Node from "@/components/cesium/js/node/index";
import { getUavDataModel, findEquipmentModel } from "@/api/map/index";
import { mapGetters, mapActions } from "vuex";
import WarningDetail from "@/components/cesium/components/warning-detail";
import oilInspectionPanel from "@/components/cesium/components/oilInspection-panel";
import xgPlayer from "@/components/video-live-xgplayer/index";
import {
  getInspectionDetail,
  submitInspectionrecords,
} from "@/api/oilinspectionrecords/inspection";
import { getOilwellpoint, getOilline } from "@/api/project/map";

import plane from "@/assets/img/map/XC_64x64_.png";
import { getAlarmDetailById } from "@/api/project/index";
import { addEvent, removeEvent } from "@/util/util";

export default {
  name: "cesiumViewer",
  components: { WarningDetail, oilInspectionPanel, xgPlayer },
  props: {
    dialogData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      windowHeight: "normal",
      loadingWarningDetail: false,
      loadingRightPanel: false,
      loadingInspection: false,
      scale: null,
      degrees: 0,
      // 进度条
      videoLoading: false,
      // 是否播放
      play: false,
      // 播放条显示进度
      percentage: 0,
      // 是否开启拖动
      controlDroping: false,
      // 实际播放进度
      propessControler: 0,
      // 当前i时间显示宽度
      processingTextWidth: 150,
      // 开始时间
      startTime: new Date().getTime(),
      // 结束时间
      endTime: new Date().getTime(),
      // 无人机图层
      flylineLayer: new BaseLayer({ name: "flylineLayer" }),
      // 巡检对象图层
      inspectionLayer: new BaseLayer({ name: "inspectionLayer" }),
      // 巡检导航路线图曾
      navigationLayer: new BaseLayer({ name: "navigationLayer" }),
      // 报警图层
      warningLayer: new BaseLayer({ name: "warningLayer" }),
      // 当缓存数据
      currentList: [],
      // 当前缓存数据最大条数
      maxlength: 20,
      // 当前播放的数据的位置
      currentIndex: 0,
      // 每次加载的数据量
      trackLength: 10,
      // 地图2D、3D状态
      viewModel: 2,
      // 报警面板
      showWarningDetail: false,
      // 地图2D、3D状态
      isVec: false,
      // 巡检对象属性面板编辑
      showRightPanel: false,
      // 巡检对象备注
      recordShow: false,
      // 备注提交按钮
      recordLoading: false,
      // 巡检对象备注
      remark: "",
      // 视频显示
      siderContentShow: false,
      //展示点击的巡检对象
      showInspection: {},
      // 右侧属性面板
      currentEntity: {
        lng: null,
        lat: null,
        linePositions: [],
      },
      // 视频播放结束
      videoHasEnded: false,
      // 无人机图标
      plane,
      // 报警列表
      warningList: [],
      // 当前选择报警
      selectWarning: {},
      // chunkLoading
      currentLoadingChunkId: "",
      // 需要停止的加载片段的ids
      stopLoadingChunkMap: {},
      lonArr: [],
    };
  },
  computed: {
    ...mapGetters(["selectInspection"]),
    //
    passedTime() {
      let total = this.endTime - this.startTime;
      let current = (this.percentage * total) / 100;
      let date = new Date(parseInt(current));
      let Hour =
        date.getHours() >= 8 ? date.getHours() - 8 : 24 - date.getHours();
      let Minute = date.getMinutes();
      let Sechond = date.getSeconds();
      Hour = Hour < 10 ? "0" + Hour : Hour;
      Minute = Minute < 10 ? "0" + Minute : Minute;
      Sechond = Sechond < 10 ? "0" + Sechond : Sechond;
      let GMT = (Hour === "00" ? "" : Hour + ":") + Minute + ":" + Sechond;
      return GMT;
    },
    // 总时间
    totalTime() {
      let date = new Date(parseInt(this.endTime - this.startTime));
      let Hour =
        date.getHours() >= 8 ? date.getHours() - 8 : 24 - date.getHours();
      let Minute = date.getMinutes();
      let Sechond = date.getSeconds();
      Hour = Hour < 10 ? "0" + Hour : Hour;
      Minute = Minute < 10 ? "0" + Minute : Minute;
      Sechond = Sechond < 10 ? "0" + Sechond : Sechond;
      let GMT = Hour + ":" + Minute + ":" + Sechond;
      return GMT;
    },
    // 视频地址
    videoUrl() {
      if (this.selectInspection && this.selectInspection.videoUrl) {
        return this.selectInspection.videoUrl;
      }
      return "";
    },
    warningDetailClass() {
      return this.showRightPanel ? "open-oilInspection-panel" : "";
    },
    WarningDetailRight() {
      let right = 386;
      if (this.windowHeight !== "normal") {
        right = 445;
      }
      if (this.showRightPanel) {
        right = right + 286;
      }
      return right;
    },
    InspectionInfoClass() {
      let className = "";
      if (this.windowHeight !== "normal") {
        className = "min-margin";
      }
      if (this.showRightPanel) {
        className = className + " open-oilInspection-panel";
      }
      return className;
    },
  },
  watch: {
    selectInspection: {
      handler(now) {
        if (now && now.hasOwnProperty("id")) {
          this.showInspection = {};
          this.changeViewer();
        } else {
          this.inspectionLayer.clear();
          this.flylineLayer.clear();
          this.warningLayer.clear();
          this.navigationLayer.clear();
          this.closeSiderContent();
          this.showInspection = {};
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // 加载地图
    this.closeSiderContent();
    addEvent(window, "resize", this.resize);
    this.resize();
    this.initMap();
    this.$EventBus.$off("Inspection-record-flyto");
    this.$EventBus.$on("Inspection-record-flyto", this.flyTo);
    this.$EventBus.$off("map-flyTo");
    this.$EventBus.$on("map-flyTo", (lng, lat, height) => {
      this.flyToByLngLat(lng, lat, height);
    });
  },
  methods: {
    ...mapActions(["setWsTaskInfo"]),

    //
    initMap() {
      const viewer = new Map3d("cesiumContainer_inspection_records", {
        sceneMode: this.viewModel,
      });
      this.$options.map3d = viewer;
      this.initLayer();
      if (this.$options.timer) {
        clearTimeout(this.$options.timer);
        this.$options.timer = null;
      }
      this.currentIndex = 0;
      this.setEditModel(true);
      // 弹窗位置与球体拖拽事件关联
      const removeHandler = viewer.scene.postRender.addEventListener(() => {
        let self = this;
        const heading = viewer.scene.camera.heading;
        if (heading) {
          self.degrees = 360 - Cesium.Math.toDegrees(heading);
        } else {
          self.degrees = 0;
        }
        const { distance } = viewer.getScale();
        self.scale = distance;
      });
    },

    // 初始化图层
    initLayer() {
      this.$options.map3d.layerManager.add(this.flylineLayer);
      this.$options.map3d.layerManager.add(this.inspectionLayer);
      this.$options.map3d.layerManager.add(this.navigationLayer);
      this.$options.map3d.layerManager.add(this.warningLayer);
    },

    changeViewer() {
      // 暂停播放
      this.closeSiderContent();
      this.flylineLayer.clear();
      this.navigationLayer.clear();
      this.warningLayer.clear();
      this.closeWarningDetail();
      this.warningList = [];
      this.selectWarning = {};
      this.currentList = [];
      this.requestAlltLayers();
      // 请求巡检对象业务信息
      if (this.showRightPanel) {
        this.getInspectionBusiness(this.selectInspection.id);
      }
      // 改变进度条
      this.percentage = 0;
      this.currentIndex = 0;
      this.endTime = this.startTime;
    },

    requestAlltLayers() {
      this.requestInspectionLayer();
    },

    requestInspectionLayer() {
      this.inspectionLayer.clear();
      this.loadingInspection = true;
      getInspectionDetail({
        id: this.selectInspection.id,
      })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data.id === this.selectInspection.id) {
              this.showInspection = data;
              this.remark = data.remark;
              this.addInspectionInMap(data);
              if (data.inspectionType !== "oilwell") {
                this.addNavigationInMap(data);
              }
              this.addWarningLayer(data.alarmDataVoList);
              this.getPlaneIcon(data.sn);
            }
          }
        })
        .finally(() => {
          this.loadingInspection = false;
        });
    },

    getPlaneIcon(sn) {
      findEquipmentModel({
        sn,
      })
        .then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            if (data && data.modelIcon) {
              this.plane = data.modelIcon;
            }
          }
        })
        .finally(() => {});
    },

    addNavigationInMap(data) {
      try {
        const geojson = data.uavTrackData;
        const type = geojson.type;
        const coordinates = geojson.coordinates;
        let node;
        var positions = [];
        for (let k = 0; k < coordinates.length; k++) {
          const coordinate = coordinates[k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        node = new Node.NavigationLine({
          id: data.id,
          positions,
          show: true,
          name: data.inspectionName,
        });
        this.navigationLayer.addNode(node);
      } catch (error) {
        console.log(error);
      }
    },

    addWarningLayer(data) {
      let list = data && Array.isArray(data) ? data : [];
      for (var i = 0, l = list.length; i < l; i++) {
        let item = list[i];
        var node = new Node.WarningPoint({
          id: item.id,
          name: item.id,
          show: true,
          position: Cesium.Cartesian3.fromDegrees(
            Number(item.lon),
            Number(item.lat)
          ),
        });
        this.warningLayer.addNode(node);
      }
    },

    addInspectionInMap(data) {
      try {
        const geojson =
          data.inspectionObject && data.inspectionObject.geometry
            ? data.inspectionObject.geometry
            : "";
        const type = geojson.type;
        const coordinates = geojson.coordinates;
        let node;
        if (type === "Point") {
          node = new Node.OilWellPoint({
            id: data.inspectionId,
            name: data.inspectionName,
            show: true,
            position: Cesium.Cartesian3.fromDegrees(...coordinates),
          });
          node.entity.canEdit = true;
          node.entity.drawType = "oilWell";
          this.currentEntity.lng = coordinates[0];
          this.currentEntity.lat = coordinates[1];
          this.flyToByLngLat(coordinates[0], coordinates[1], 1000 * 10);
        }
        if (type === "LineString") {
          var positions = [];
          for (let k = 0; k < coordinates.length; k++) {
            const coordinate = coordinates[k];
            const position = Cesium.Cartesian3.fromDegrees(...coordinate);
            positions.push(position);
          }
          node = new Node.OilPipeline({
            id: data.inspectionId,
            positions,
            show: true,
            name: data.inspectionName,
          });
          let oilWellPoints = node.getInflectionPoint();
          let linePositions = oilWellPoints.map((item) => {
            let position = item.entity.position._value;
            let cartographic =
              Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
            return {
              lon: Cesium.Math.toDegrees(cartographic.longitude),
              lat: Cesium.Math.toDegrees(cartographic.latitude),
              id: item.id,
            };
          });
          this.currentEntity.linePositions = linePositions;
          this.flyToByLngLat(coordinates[0][0], coordinates[0][1], 1000 * 10);
        }
        this.inspectionLayer.addNode(node);
      } catch (error) {
        console.log(error);
      }
    },

    switchVideoURL() {
      return new Promise((resolve, reject) => {
        this.$refs.videoLive
          .switchURL()
          .then(() => {
            this.endTime = new Date(
              this.startTime + this.$refs.videoLive.player.duration * 1000
            ).getTime();
            this.videoLoading = false;
            this.play = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    resize(e) {
      const h = document.documentElement.clientHeight;
      if (h < 850) {
        this.windowHeight = `small`;
      } else {
        this.windowHeight = `normal`;
      }
    },

    videoPlay() {
      this.videoLoading = false;
      this.play = true;
      if (this.videoHasEnded) {
        this.videoHasEnded = false;
        this.flylineLayer.clear();
        setTimeout(() => {
          this.startChecked();
        }, 1000);
      } else {
        this.startChecked();
      }
    },

    videoPause() {
      this.play = false;
    },

    videoEnd() {
      this.endChecked();
      this.currentIndex = 0;
      this.percentage = 0;
      this.play = false;
      this.videoHasEnded = true;
    },

    startProgress() {
      // this.controlDroping = true
    },

    footerMouseUp(evt) {
      if (this.controlDroping) {
        this.controlDroping = false;
      }
    },

    footerMouseMove(e) {
      if (this.controlDroping) {
        // this.setControllerPosition(e)
      }
    },

    setControllerPosition(evt) {
      if (!this.$refs.videoLive.player) {
        return;
      }
      this.videoLoading = true;
      this.flylineLayer.clear();
      this.endChecked();
      var left = evt.clientX;
      var footer = this.$refs.footer.getBoundingClientRect();
      // 鼠标相对footer的Dom元素的位置
      var leftFooter = left - footer.left;
      // 鼠标相对进度条的位置
      var leftPro = leftFooter - 52 - this.processingTextWidth;
      if (leftPro < 0) {
        this.percentage = 0;
      } else if (footer.width < leftFooter + 120) {
        this.percentage = 100;
      } else {
        this.percentage =
          (leftPro * 100) /
          (footer.width - 52 - this.processingTextWidth - 120);
      }
      let duration = this.$refs.videoLive.player.duration;
      let currentTime = (duration * this.percentage) / 100;
      this.$refs.videoLive.setCurrentTime(currentTime);
      this.currentIndex = parseInt(currentTime);
      // console.log(`currentIndex:`, this.currentIndex);
      setTimeout(() => {
        this.startChecked();
        this.videoLoading = false;
      }, 500);
    },

    // 开始和停止播放
    triggerPlay() {
      if (!this.play) {
        this.startPlay();
      } else {
        this.endPlay();
      }
    },

    startPlay(stamp) {
      if (!this.showInspection && !this.showInspection.id) {
        return;
      }
      this.$refs.videoLive.play(stamp);
    },

    endPlay() {
      if (this.$refs.videoLive && this.$refs.videoLive.pause) {
        this.$refs.videoLive.pause();
      }
      this.endChecked();
    },

    startChecked() {
      this.checkedPlayer();
    },

    endChecked() {
      this.stopLoadingTrack();
      if (this.$options.timer) {
        clearTimeout(this.$options.timer);
        this.$options.timer = null;
      }
    },

    checkedPlayer() {
      if (this.play) {
        let currentTime =
          this.$refs.videoLive && this.$refs.videoLive.player
            ? this.$refs.videoLive.player.currentTime
            : 0;
        this.percentage =
          (currentTime * 1000 * 100) / (this.endTime - this.startTime);
        this.drawTrack();
        this.$options.timer = setTimeout(() => {
          this.checkedPlayer();
        }, 1000);
      }
    },

    // 开始轨迹播放
    drawTrack() {
      const startTime = this.getStampByDateFomat(
        this.selectInspection.startTime
      );
      let currentTime = parseInt(this.$refs.videoLive.player.currentTime);
      let requestStartTime = this.currentIndex * 1000 + startTime;
      if (!this.theChunkHasLoaded(this.trackLength, requestStartTime)) {
        this.loadTrack(Cesium.createGuid());
        return;
      }
      let lastIndex = this.currentIndex;
      if (this.currentIndex === 0) {
        for (var i = 0; i < currentTime - this.currentIndex + 1; i++) {
          if (this.currentIndex + i < this.currentList.length) {
            if (!this.currentList[this.currentIndex + i]) {
              break;
            }
            lastIndex++;
            this.updateFlyLine(this.currentList[this.currentIndex + i]);
          }
        }
        this.currentIndex = lastIndex;
      } else {
        for (var i = 0; i < currentTime - this.currentIndex; i++) {
          if (this.currentIndex + i + 1 < this.currentList.length) {
            if (!this.currentList[this.currentIndex + i + 1]) {
              break;
            }
            lastIndex++;
            this.updateFlyLine(this.currentList[this.currentIndex + i + 1]);
          }
        }
        this.currentIndex = lastIndex;
      }
    },

    loadTrack(id) {
      return new Promise((resolve, reject) => {
        if (this.currentLoadingChunkId) {
          return;
        }
        this.currentLoadingChunkId = id;
        // console.log(`开始下载的片段并记录：${id}`);
        const startTime = this.getStampByDateFomat(
          this.selectInspection.startTime
        );
        const endTime = this.getStampByDateFomat(this.selectInspection.endTime);
        const max = parseInt((endTime - startTime) / 1000);
        const taskID = this.selectInspection.taskId;
        const size = this.trackLength > max ? max : this.trackLength;
        const duration = this.$refs.videoLive.player
          ? this.$refs.videoLive.player.duration
          : (endTime - startTime) / 1000;
        // console.log(`this.percentage: ${this.percentage}`);
        let requestStartTime =
          (duration * 1000 * this.percentage) / 100 + startTime;
        const index = parseInt((requestStartTime - startTime) / 1000);
        if (this.currentIndex < index && index > 0) {
          requestStartTime = requestStartTime - 1000;
        }
        this.loadChunk(taskID, size, requestStartTime, id)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    stopLoadingTrack() {
      if (this.currentLoadingChunkId) {
        this.stopLoadingChunkMap[this.currentLoadingChunkId] = true;
        // console.log(`已将正在执行的任务${this.currentLoadingChunkId}记录到停止运行map中`,this.stopLoadingChunkMap);
        this.currentLoadingChunkId = "";
      }
    },

    loadChunk(taskID, size, requestStartTime, id) {
      return new Promise((resolve, reject) => {
        const inspectionId = this.selectInspection.inspectionId;
        const startTime = this.getStampByDateFomat(
          this.selectInspection.startTime
        );
        let endTime = this.getStampByDateFomat(this.selectInspection.endTime);
        const duration =
          this.$refs.videoLive.player &&
          typeof this.$refs.videoLive.player.duration === "number" &&
          !isNaN(this.$refs.videoLive.player.duration)
            ? this.$refs.videoLive.player.duration
            : (endTime - startTime) / 1000;
        endTime = startTime + duration * 1000;
        const index = parseInt((requestStartTime - startTime) / 1000);
        if (this.theChunkHasLoaded(size, requestStartTime)) {
          // console.log(`当前请求索引：${index} -- ${index + size} 已经获取继续获取后面数据`);
          return;
        }
        // console.log(`当前请求索引：${index}`);
        if (requestStartTime >= endTime) {
          return;
        }
        getUavDataModel({
          size,
          startTime: requestStartTime,
          taskID,
        }).then((res) => {
          if (res.data.code === 200) {
            if (this.selectInspection.inspectionId == inspectionId) {
              if (this.stopLoadingChunkMap[id]) {
                delete this.stopLoadingChunkMap[id];
                // console.log(`正在下载的片段：${id} 已经停止`);
                return;
              }
              let data =
                res.data.data && Array.isArray(res.data.data)
                  ? res.data.data
                  : [];
              let index = parseInt((requestStartTime - startTime) / 1000);
              for (var i = 0; i < data.length; i++) {
                this.currentList[index + i] = data[i];
              }
              // 开发环境调试工具
              if (process.env.NODE_ENV === "development") {
                // this.logTrackLoading()
              }
              resolve();
              if (data.length === 0) {
                // 数据已经加载完成  结束加载轨迹数据
                // console.log(`所有轨迹加载完结束:${id}`);
                this.currentLoadingChunkId = "";
                return;
              }
              if (requestStartTime + size * 1000 <= endTime - 1000) {
                let _size = size;
                let next = requestStartTime + size * 1000;
                if (next + _size * 1000 >= endTime) {
                  _size = parseInt((endTime - requestStartTime) / 1000);
                }
                setTimeout(() => {
                  this.loadChunk(taskID, _size, next, id);
                }, 0);
                return;
              } else {
                // console.log(`所有轨迹加载完结束:${id}`);
                this.currentLoadingChunkId = "";
                return;
              }
            }
            reject("inspectionId不一致");
          } else {
            reject("轨迹加载失败");
          }
        });
      });
    },

    logTrackLoading() {
      let str = "";
      for (var i = 0; i < this.currentList.length; i++) {
        if (i == 0) {
          if (this.currentList[i]) {
            str = str + i;
          } else {
            str = str + "-";
          }
        } else {
          if (this.currentList[i]) {
            if (this.currentList[i - 1]) {
              str = str + "*";
            } else {
              str = str + i;
            }
          } else {
            if (this.currentList[i - 1]) {
              str = str + i;
            } else {
              str = str + "-";
            }
          }
        }
      }
      console.log(str);
    },

    theChunkHasLoaded(size, requestStartTime) {
      let startTime = this.getStampByDateFomat(this.selectInspection.startTime);
      let index = parseInt((requestStartTime - startTime) / 1000);
      for (var i = 0; i < size; i++) {
        if (!this.currentList[index + i]) {
          return false;
        }
      }
      return true;
    },

    getStampByDateFomat(str) {
      const arr = str.split(" ");
      const date = arr[0];
      const time = arr[1];
      const dateInfo = date.split("-");
      const timeInfo = time.split(":");
      const year = Number(dateInfo[0]);
      const month = Number(dateInfo[1]);
      const day = Number(dateInfo[2]);
      const hour = Number(timeInfo[0]);
      const min = Number(timeInfo[1]);
      const second = Number(timeInfo[2]);
      return new Date(year, month - 1, day, hour, min, second).getTime();
    },

    updateFlyLine(data) {
      try {
        let lon = Number(data.lon);
        let lat = Number(data.lat);
        let yaw = Number(data.yaw);
        let height = data.fieldHeight;
        this.lonArr = [lon, lat];
        this.setWsTaskInfo(data);
        const position = Cesium.Cartesian3.fromDegrees(
          lon * 1,
          lat * 1,
          height
        );
        let uav = this.flylineLayer.getById("uav");
        if (!uav) {
          uav = new Cesium.Entity({
            id: "uav",
            position: position,
            billboard: {
              image: this.plane,
              width: 56,
              height: 56,
            },
            polyline: {
              positions: [position],
              material: Cesium.Color.fromCssColorString("#AAFF00"),
              width: 2.0,
              depthFailMaterial: Cesium.Color.fromCssColorString("#AAFF00"),
            },
          });
          this.flylineLayer.add(uav);
          setTimeout(() => {
            this.$options.map3d.camera.flyTo({
              destination: new Cesium.Cartesian3.fromDegrees(
                lon,
                lat,
                1000 * 10
              ),
            });
          }, 50);
        } else {
          uav.position = position;
          let old;
          if (!uav.position.getValue) {
            old = uav.polyline.positions;
          } else {
            old = uav.polyline.positions.getValue().slice();
          }
          uav.billboard.rotation = -Cesium.Math.toRadians(yaw || 0);
          uav.polyline.positions = new Cesium.CallbackProperty(function () {
            return [...old, position];
          }, false);
          this.play = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    setEditModel(en = true) {
      this.activeDraw = "";
      if (en === true) {
        this.drawFinish = true;
        this.$options.map3d._container.style.cursor = "default";
      }
      // 绘制完成后需要自动进入编辑模式 同时对部分元素
      let afterFinish = (model, origin, selectFeature) => {
        const type =
          selectFeature && selectFeature.drawType
            ? selectFeature.drawType
            : undefined;
        switch (type) {
          case "WarningPoint":
            this.selectWarning = {};
            this.loadingWarningDetail = true;
            getAlarmDetailById({
              id: selectFeature.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  let data = res.data.data;
                  data.lng = data.lon;
                  data.imgs = data.picUrl.split(",");
                  this.selectWarning = data ? data : {};
                }
              })
              .finally(() => {
                this.loadingWarningDetail = false;
              });
            this.openWarningDetail();
            break;
          default:
            break;
        }
      };
      // 注册完交互事件的对用回调
      this.$options.map3d.triggerEditModel(en, {
        map: this.$options.map3d,
        vueHandler: this,
        afterFinish,
      });
    },

    editRecord() {
      this.recordShow = true;
    },

    openRightPannel(id) {
      this.showRightPanel = true;
      this.getInspectionBusiness(id);
    },

    getInspectionBusiness(id) {
      this.loadingRightPanel = true;
      if (this.selectInspection.inspectionType === "oilwell") {
        getOilwellpoint({
          id,
        })
          .then((res) => {
            if (res.data.code === 200) {
              const data = res.data.data;
              this.setCurrentEntity({
                id: data.id,
                drawType: "oilWell",
                text: data.name,
                path: data.dirId,
                createBy: data.createBy,
                deptId: data.deptId,
                linkman: data.linkman,
                tel: data.tel,
                meteringStation: data.meteringStation,
                startCondition: data.startCondition,
                endCondition: data.endCondition,
              });
            }
          })
          .finally(() => {
            this.loadingRightPanel = false;
          });
      } else {
        getOilline({
          id,
        })
          .then((res) => {
            if (res.data.code === 200) {
              const data = res.data.data;
              this.setCurrentEntity({
                id: data.id,
                drawType: "oilLine",
                text: data.name,
                path: data.dirId,
                createBy: data.createBy,
                deptId: data.deptId,
                linkman: data.linkman,
                tel: data.tel,
                meteringStation: data.meteringStation,
                startCondition: data.startCondition,
                endCondition: data.endCondition,
              });
            }
          })
          .finally(() => {
            this.loadingRightPanel = false;
          });
      }
    },

    openWarningDetail() {
      this.showWarningDetail = true;
    },

    closeWarningDetail() {
      this.showWarningDetail = false;
    },

    changeViewModel() {
      const newMode = this.viewModel === 3 ? 2 : 3;
      this.viewModel = newMode;
      let cameraInfo = this.$options.map3d.getCameraInfo();
      this.$options.map3d.scene.screenSpaceCameraController.enableTilt = false; //禁止倾斜相机
      const result = this.$options.map3d.camera.pickEllipsoid(
        new Cesium.Cartesian2(
          this.$options.map3d.scene.canvas.clientWidth / 2,
          this.$options.map3d.scene.canvas.clientHeight / 2
        )
      );

      const d = this.$options.map3d.camera.positionCartographic;
      const curPosition =
        Cesium.Ellipsoid.WGS84.cartesianToCartographic(result);

      let bound = new Cesium.BoundingSphere(
        new Cesium.Cartesian3.fromRadians(
          curPosition.longitude,
          curPosition.latitude
        ),
        d.height
      );

      if (newMode === 2) {
        cameraInfo.orientation = {
          heading: 0,
          pitch: Cesium.Math.toRadians(-90.0), //look down
          roll: 0,
        };
        this.$options.map3d.camera.flyToBoundingSphere(bound, {
          offset: cameraInfo.orientation,
          duration: 0.5,
        });
      } else {
        bound.radius = d.height * 0.15;
        cameraInfo.orientation = {
          heading: 0,
          pitch: Cesium.Math.toRadians(-45.0), //look in 3d
          roll: 0,
        };
        this.$options.map3d.camera.flyToBoundingSphere(bound, {
          offset: cameraInfo.orientation,
          duration: 0.5,
        });
      }
    },

    zoom(amount) {
      let cartographic;
      if (this.viewModel === 3) {
        const cameraPos = this.$options.map3d.camera.position;
        const ellipsoid = this.$options.map3d.scene.globe.ellipsoid;
        cartographic = ellipsoid.cartesianToCartographic(cameraPos);
      } else {
        cartographic = this.$options.map3d.camera.positionCartographic;
      }
      const height = cartographic.height;
      const centerLon = Cesium.Math.toDegrees(cartographic.longitude);
      const centerLat = Cesium.Math.toDegrees(cartographic.latitude);

      // 镜头动画
      this.$options.map3d.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          centerLon,
          centerLat,
          amount > 0 ? height / 1.8 : height * 1.8
        ),
        duration: 1.0,
      });
    },

    switchLayerType() {
      this.isVec = !this.isVec;
      const type = this.isVec ? "vector" : "satellite";
      this.$options.map3d.switchLayer(type);
    },

    closePanel() {
      this.showRightPanel = false;
    },

    closeRecord() {
      this.recordShow = false;
    },

    submitRecord() {
      this.recordLoading = true;
      submitInspectionrecords({
        id: this.showInspection.id,
        remark: this.remark,
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message.success("操作成功");
            this.loadingInspection = true;
            this.recordShow = false;
            getInspectionDetail({
              id: this.selectInspection.id,
            })
              .then((res) => {
                if (res.data.code === 200) {
                  let data = res.data.data;
                  if (data.id === this.selectInspection.id) {
                    this.showInspection = data;
                  }
                }
              })
              .finally(() => {
                this.loadingInspection = false;
              });
          }
        })
        .finally(() => {
          this.recordLoading = false;
        });
    },

    closeSiderContent() {
      this.endPlay();
      this.currentIndex = 0;
      // 清空缓存数据
      this.currentList = [];
      if (this.$refs.videoLive) {
        this.$refs.videoLive.closeLive();
      }
      this.flylineLayer.clear();
      this.siderContentShow = false;
      this.percentage = 0;
      setTimeout(() => {
        if (this.$refs.videoLive) {
          this.$refs.videoLive.destroy();
        }
      }, 0);
    },

    showLive() {
      if (!this.selectInspection || !this.selectInspection.id) {
        this.$refs.videoLive.closeLive();
        return this.$message.warning("请在左侧列表，选择一个巡检对象");
      }
      // 进度条loading
      this.videoLoading = true;
      this.siderContentShow = true;
      const startTime = this.getStampByDateFomat(
        this.selectInspection.startTime
      );
      const endTime = this.getStampByDateFomat(this.selectInspection.endTime);
      const size = parseInt((endTime - startTime) / 1000);
      this.currentList.length = size;
      this.loadTrack(Cesium.createGuid()).then(() => {
        // 加载视频数据源
        this.switchVideoURL();
      });
    },

    getInspectionTypeName(type) {
      const inspectionTypeList = [
        {
          label: "油井",
          value: "oilwell",
        },
        {
          label: "管线",
          value: "oilline",
        },
      ];
      for (let i = 0; i < inspectionTypeList.length; i++) {
        if (type === inspectionTypeList[i].value) {
          return inspectionTypeList[i].label;
        }
      }
      return "";
    },

    // 设置右侧面板属性
    setCurrentEntity(value) {
      this.currentEntity = JSON.parse(
        JSON.stringify(Object.assign(this.currentEntity, value))
      );
    },

    flyTo(id) {
      try {
        const node = this.inspectionLayer.getNodeById(id);
        const cartesian = node.entity.position._value;
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        // 弧度转为角度（经纬度）
        const lon = Cesium.Math.toDegrees(cartographic.longitude);
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        this.$options.map3d.scene.camera.setView({
          destination: Cesium.Cartesian3.fromDegrees(lon, lat, 1000 * 10),
        });
      } catch (error) {
        console.error(error);
      }
    },

    flyToByLngLat(lng, lat, height) {
      if (typeof lng !== "number" && typeof lat !== "number") {
        return;
      }
      this.$options.map3d.camera.setView({
        destination: new Cesium.Cartesian3.fromDegrees(lng, lat, 1000 * 10),
      });
    },

    warningSuccess() {
      this.loadingInspection = true;
      getInspectionDetail({
        id: this.selectInspection.id,
      })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data.id === this.selectInspection.id) {
              this.showInspection = data;
              this.remark = data.remark;
              this.warningLayer.clear();
              this.addWarningLayer(data.alarmDataVoList);
            }
          }
        })
        .finally(() => {
          this.loadingInspection = false;
        });
    },
  },
  beforeDestroy() {
    if (this.$options.map3d) {
      this.$options.map3d.destroy();
    }
    this.endChecked();
    removeEvent(window, "resize", this.resize);
  },
};
</script>
<style scoped lang="scss">
.inspection-records-map-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  #cesiumContainer_inspection_records {
    width: 100%;
    height: 100%;
  }

  .footer {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .play-controller {
      width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .video-play {
        width: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .processing {
        cursor: pointer;
        margin-left: 10px;
        position: relative;
        width: calc(100% - 120px);
        position: relative;
        top: 1px;
        .processing-controller {
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          background-color: #fff;
          width: 16px;
          height: 16px;
          border-radius: 8px;
        }
      }
    }
    .tool-controller {
      width: 100px;
      display: flex;
      justify-content: end;
      .full {
        width: 16px;
        height: 16px;
      }
    }
  }

  .min-margin {
    top: 16px !important;
    padding: 10px 33px 10px 33px !important;
    .inspection-pannel-item:not(:last-child) {
      margin-bottom: 4px !important;
    }
  }

  .inspection-pannel {
    box-sizing: border-box;
    width: 346px;
    background-color: #0c0c0ccc;
    min-height: 100px;
    border-radius: 8px;
    position: absolute;
    right: 26px;
    top: 28px;
    padding: 25px 33px 33px 33px;
    color: #ffffff;
    .inspection-pannel-item:not(:last-child) {
      margin-bottom: 15px;
    }
    .inspection-pannel-item {
      font-size: 14px;
      line-height: 18px;
      .pannel-item-title {
        display: inline-block;
        vertical-align: top;
        width: 73px;
      }
      .pannel-item-content {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 74px);
        word-wrap: break-word;
        word-break: break-all;
      }
      .edit-icon {
        cursor: pointer;
      }
    }
    .inspection-pannel-item:first-child {
      .pannel-item-content {
        color: #387bed;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  #baseController {
    position: absolute;
    bottom: 50px;
    right: 1%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    #navigationHelpButtonContainer {
      width: 21px;
      height: 42px;
      margin-bottom: 10px;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2d333e;
      color: #fff;
      cursor: pointer;
      border: 1px solid #2d333e;
    }

    .forbidden {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      margin-bottom: 10px;
    }

    .drawBtn {
      width: 40px;
      height: 40px;
    }

    .zoomBtn {
      width: 30px;
      height: 30px;
    }

    .btn > img {
      width: 16px;
    }

    .bottomBorder {
      border-bottom: 1px solid #171c28;
    }
    .btn:hover {
      filter: brightness(150%);
      border: 1px solid #171c28;
    }
    .flexCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .layerSwitch {
      margin-top: 20px;
      width: 32px;
      height: 32px;
      border-radius: 18px;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }

  .open-oilInspection-panel {
    right: 286px !important;
  }

  .sider-content {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    position: absolute;
    z-index: 98;
    min-width: 150px;
    box-sizing: border-box;
    .content-header {
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      box-sizing: border-box;
      .close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
      }
    }
    .height-258 {
      height: 258px;
    }
    .video-container {
      height: 100%;
    }
  }

  .oilInspection-panel {
    z-index: 99;
  }

  .scaler {
    position: absolute;
    bottom: 3%;
    padding: 2px;
    right: 1%;
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    .scaler-text {
      text-align: center;
    }
    .scaler-ui {
      width: 100%;
      height: 10px;
      border: 1px solid #fff;
      border-top: none;
    }
  }
}
</style>
